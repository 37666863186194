import AboutUs from './Components/AboutUs/AboutUs';
import AreasOfAtuation from './Components/AreasOfAtuation/AreasOfAtuation';
import CardCarousel from './Components/CardCarousel/CardCarousel';
import Footer from './Components/Footer/Footer';
import GoogleReviews from './Components/GoogleReviews/GoogleReviews';
import HeroSection from './Components/HeroSection/HeroSection'

function App() {
  return (
    <>
      <HeroSection />
      <AreasOfAtuation />
      <AboutUs />
      <CardCarousel />
      <GoogleReviews />
      <Footer />
    </>
  );
}

export default App;
