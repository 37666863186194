import React from 'react';
import styles from './HeroSection.module.css';
import logoRenan from '../../assets/logorenan.png'
import teste from '../../assets/RenanEquipe.jpg'

function HeroSection() {
    return (
        <div className={styles.heroContainer}>
            <img className={styles.logo} src={logoRenan} alt="Renan Roa Advocacia" />
            <div className={styles.itensDiv}>
            <h1>Somos um grupo de advogados especializados nas mais diversas áreas do Direito, prontos para atender todas suas necessidades.</h1>
            <p>Entre em contato agora mesmo! Não corra o risco de perder o prazo para ir atrás dos seus direitos.</p>
            <div >
            <a href="https://wa.me/+5544998632325?text=Olá%20Gostaria%20de%20falar%20com%20o%20Advogado%20Especialista" target="_blank" rel="noopener noreferrer">
                    <button className={styles.heroButton}>Entre em Contato</button>
                </a>
            </div>
            </div>
        </div>
    );
}

export default HeroSection;
