import React, { useRef, useState, useEffect } from 'react';
import styles from './CardCarousel.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import RenanEquipe from '../../assets/RenanEquipe.jpg'
import FaEquipe from '../../assets/FaaEquipe.jpg'
import Flavia from '../../assets/FlaviaEquipe.jpg'

const CardCarousel = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const slidesPerViewConfig = windowWidth <= 576 ? 1 : 3;

    return (
        <>
            <Swiper
                slidesPerView={slidesPerViewConfig}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}

                className={styles.mySwiper}
            >


                <SwiperSlide>
                    <div className={styles.card}>
                        <img src={FaEquipe} alt="Fabiana Gabriele" className={styles.cardImage} />
                        <div className={styles.cardText}>Fabiana Gabriele - Secretaria Jurídica</div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className={styles.card}>
                        <img src={RenanEquipe} alt="Dr. Renan Roa" className={styles.cardImage} />
                        <div className={styles.cardText}>Dr. Renan Roa - OAB/PR: 118.147</div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className={styles.card}>
                        <img src={Flavia} alt="Dra. Flávia Gutierrez" className={styles.cardImage} />
                        <div className={styles.cardText}>Dra. Flávia Gutierrez - OAB/PR: 117.657</div>
                    </div>
                </SwiperSlide>

                {/* <SwiperSlide>
                    <div className={styles.card}>
                        <img src="path/to/your/image.jpg" alt="Description" className={styles.cardImage} />
                        <div className={styles.cardText}>Slide 4</div>
                    </div>
                </SwiperSlide> */}
            </Swiper>
        </>
    )
}

export default CardCarousel;
