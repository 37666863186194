import React from 'react'
import styles from './AboutUs.module.css';
import Office from '../../assets/office.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const AboutUs = () => {
    return (
        <>
        <div className={styles.border}></div>
            <div className={styles.container}>
                <div className={styles.contentGrid}>
                    <h2 className={styles.title}>Quem Somos</h2>
                    <p className={styles.ptext}>Renan Roa Advocacia é um escritório que oferece atendimento de excelência. Com atuação em nível nacional de forma totalmente digital, nosso objetivo é acelerar a resolução das causas e prezar pelo conforto dos nossos clientes. Conta em seus quadros, profissionais altamente capacitados, preocupados com a constante atualização dos seus conhecimentos e implantação de inovações tecnológicas para atender nossos clientes com eficiência.</p>
                    <a href="https://wa.me/+5544998632325?text=Olá%20Gostaria%20de%20falar%20com%20o%20Advogado%20Especialista" target="_blank" rel="noopener noreferrer">
                        <button className={styles.learnMoreButton}>
                            <FontAwesomeIcon className={styles.iconClass} icon={faWhatsapp} size="2x" />
                            Fale Conosco no Whatsapp
                        </button>

                    </a>

                </div>
                <div className={styles.imageGrid}>
                    <img src={Office} alt="Logo" className={styles.aboutImage} />
                </div>
            </div>
        </>
    );
}

export default AboutUs
