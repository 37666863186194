import React, { useState, useEffect } from 'react';
import styles from './GoogleReviews.module.css'
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


import GoogleLogo from '../../assets/LogoGoogle.avif'
import FotoReview from '../../assets/fototeste.png'
import FotoReview2 from '../../assets/fotoreview.png'
import FotoReview3 from '../../assets/fotoreview3.png'
import FotoReview4 from '../../assets/fotoreview4.png'
import FotoReview5 from '../../assets/fotoreview5.png'
import FotoReview6 from '../../assets/fotoreview6.png'
import FotoReview7 from '../../assets/fotoreview7.png'

const GoogleReviews = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalCards = 6;
    let cardWidth = 300;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    let cardsToShow = isMobile ? 1 : 3;
    let cardMargin = isMobile ? 20 : 10;
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    if (isMobile) {
        cardWidth = (window.innerWidth - 40) * 0.6;  // Mudar essa linha se o mobile não estiver pegando
        cardsToShow = 1;
    }
    const containerWidth = (cardWidth + cardMargin * 2) * cardsToShow;
    const translateAmount = currentIndex * (cardWidth + cardMargin * 2);




    const cards = [

        {
            text: 'Super atencioso, ótimo atendimento, excelente no que faz ,Recomendo o serviço do Dr. Renan pela minha experiência nos processos atendidos, por sua propriedade neste setor desenvolve um ótimo trabalho tanto na atuação dos processos como também na comunicação, assessorando com eficiência e tirando as minhas dúvidas com clareza, sendo um profissional de alta performance.',
            name: 'Rafaella Kollavyck',
            image: FotoReview7
        },

        {
            text: 'Recomendo enfaticamente a Renan Roa Advocacia a qualquer pessoa que precise de serviços jurídicos de alta qualidade. Eles são uma equipe excepcional, que prioriza o cliente e se esforça ao máximo para alcançar resultados positivos.      ',
            name: 'Gabriel Santos',
            image: FotoReview6
        },


        {
            text: 'A nota é 10 foi muito bem atendida eu só tenho que agradecer mesmo que eu precisava de esclarecer algumas dúvidas e o doutor me esclareceu a todas as dúvidas muito obrigada      ',
            name: 'Maria Lima',
            image: FotoReview2
        },


        {
            text: 'Ótimo profissional Trabalho de excelência e agilidade . Indico e recomendo a todos.',
            name: 'Regina Pereira',
            image: FotoReview3
        },

        {
            text: 'Excelente atendimento! Dr. Renan é ótimo e está sempre em prontidão para qualquer dúvida que precisar! Super recomendo...',
            name: 'Marilia Dutra',
            image: FotoReview5
        },

        {
            text: 'Excelente atendimento, muito atencioso e esclareceu todas as dúvidas que eu tive.',
            name: 'Ingrid Oliveira',
            image: FotoReview4
        },
    ];

    const [expandedCardIndex, setExpandedCardIndex] = useState(null);

    const renderCard = (card, index) => {
        const footerMarginTop = expandedCardIndex === index ? '40px' : '10px'; // adjust the values as necessary
        const characterLimit = 170; // change to the number of characters after which "read more" should appear
        const isTextLong = card.text.length > characterLimit;
        const pHeight = card.text.length < characterLimit ? '173px' : 'auto';  // Adjust '150px' as needed
        const cardPaddingBottom = card.text.length < characterLimit ? '130px' : '10px';  // Adjust '40px' as needed
        const displayText = isTextLong && expandedCardIndex !== index
            ? `${card.text.slice(0, characterLimit)}...`
            : card.text;

        const cardBottomMargin = isTextLong || expandedCardIndex === index ? '180px' : '100px';  // Adjust '100px' as needed

        return (
            <div className={styles.card} style={{ width: `${cardWidth}px`, padding: '10px', paddingBottom: cardPaddingBottom, position: 'relative', marginBottom: cardBottomMargin, marginRight: `${cardMargin}px` }}>
                <div style={{
                    backgroundColor: '',
                    borderRadius: '10px',
                    padding: '10px',
                    border: '1px solid #ffffff',
                    boxShadow: "1px 1px 7px 0 rgba(0,0,0,.13), 0 0 2px 0 rgba(0,0,0,.05)"
                }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: "center", }}>
                        <div style={{ backgroundColor: 'white', width: '100px', height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "10px" }}>
                            <StarIcon style={{ color: '#F6BB06' }} />
                            <StarIcon style={{ color: '#F6BB06' }} />
                            <StarIcon style={{ color: '#F6BB06' }} />
                            <StarIcon style={{ color: '#F6BB06' }} />
                            <StarIcon style={{ color: '#F6BB06' }} />
                        </div>
                        <div style={{ backgroundColor: 'white', width: '30px', height: '70px', display: 'flex', alignItems: 'center' }}>
                            <img className={styles.logo} src={GoogleLogo} alt="Logo Google" />
                        </div>
                    </div>
                    <p style={{ textAlign: 'center', marginTop: '5px', height: pHeight }}>{displayText}</p>
                    {isTextLong && (
                        <p 
                            style={{ display: 'block', margin: 'auto', marginTop: '10px', color: '#9F9F9F', cursor: 'pointer'                         }}
                            onClick={() =>
                                setExpandedCardIndex(expandedCardIndex === index ? null : index)
                            }
                        >
                            {expandedCardIndex === index ? "Ler menos" : "Leia mais"}
                        </p>
                    )}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: footerMarginTop }}>
                    <img src={card.image} alt="Avaliação dos Clientes" />
                    <p style={{ marginLeft: '10px' }}>{card.name}</p>
                </div>
            </div>
        );
    };

    const handleNextClick = () => {
        if (currentIndex < totalCards - cardsToShow) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '50vh', marginTop: '90px', paddingBottom: '100px', paddingTop: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px', flexWrap: 'wrap', marginTop: '20px' }}> {/* Added marginTop here */}
                <StarIcon style={{ color: '#F6BB06', fontSize: isMobile ? '18px' : '24px' }} /> {/* Adjusted size for mobile */}
                <StarIcon style={{ color: '#F6BB06', fontSize: isMobile ? '18px' : '24px' }} />
                <StarIcon style={{ color: '#F6BB06', fontSize: isMobile ? '18px' : '24px' }} />
                <StarIcon style={{ color: '#F6BB06', fontSize: isMobile ? '18px' : '24px' }} />
                <StarIcon style={{ color: '#F6BB06', fontSize: isMobile ? '18px' : '24px' }} />
            </div>
            <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Somos NOTA MÁXIMA nas avaliações de clientes no Google.</h3>
            <p className={styles.pcolor}>Confira abaixo alguns depoimentos. </p>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingBottom: '0px' }}>
                <div style={{ marginTop: '10px', cursor: currentIndex > 0 ? 'pointer' : 'not-allowed', opacity: currentIndex > 0 ? 1 : 0.5 }}>
                    <ArrowBackIosIcon onClick={handlePrevClick} />
                </div>

                <div style={{ width: `${containerWidth}px`, overflow: 'hidden' }}> {/* Container */}
                    <div style={{ display: 'grid', gridTemplateColumns: `repeat(${totalCards}, ${cardWidth + cardMargin * 2}px)`, transform: `translateX(-${translateAmount}px)`, transition: 'transform 0.5s ease' }}> {/* Cards */}
                        {cards.map((card, index) => renderCard(card, index))}
                    </div>
                </div>

                <div style={{ marginTop: '10px', cursor: currentIndex < totalCards - cardsToShow ? 'pointer' : 'not-allowed', opacity: currentIndex < totalCards - cardsToShow ? 1 : 0.5 }}>
                    <ArrowForwardIosIcon onClick={handleNextClick} />
                </div>
            </div>
        </div>
    );
};

export default GoogleReviews;
