import React from 'react';
import styles from './AreasOfAtuation.module.css';
import Balance from '../../assets/balance.png'
import Saude from '../../assets/health.png'
import Family from '../../assets/family-law.png'
import Previdenciario from '../../assets/previdenciario.png'
import Consumer from '../../assets/consumidor.png'
import Criminal from '../../assets/criminal.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


function AreasOfAtuation() {
    const cardDataRow1 = [
        {
          iconClass: Balance,
          text: "Direito Civil"
        },
        {
          iconClass: Saude,
          text: "Direito da Saúde"
        },
        {
          iconClass: Family,
          text: "Direito de Familia"
        },
        {
          iconClass: Previdenciario,
          text: "Direito Previdenciário"
        }
      ];

      const cardDataRow2 = [
        {
          iconClass: Consumer,
          text: "Direito do Consumidor"
        },
        {
          iconClass: Criminal,
          text: "Direito Criminal"
        },
        
      ];
    
      return (
        <div className={styles.container}>
          <h1 className={styles.title}>Áreas de Atuação</h1>
          <div className={styles.cardsContainer}>
            {cardDataRow1.map((card, index) => (
              <div key={index} className={styles.card}>
                <div className={styles.iconContainer}>
                <img src={card.iconClass} alt={`Icone Card ${index + 1}`} className={styles.iconImage} />

                </div>
                <p className={styles.cardText}>{card.text}</p>
              </div>
            ))}
          </div>

          <div className={styles.cardsContainer2}>
            {cardDataRow2.map((card, index) => (
              <div key={index} className={styles.card}>
                <div className={styles.iconContainer}>
                <img src={card.iconClass} alt={`Icone card ${index + 1}`} className={styles.iconImage} />

                </div>
                <p className={styles.cardText}>{card.text}</p>
              </div>
            ))}
          </div>
          <a href="https://wa.me/+5544998632325?text=Olá%20Gostaria%20de%20falar%20com%20o%20Advogado%20Especialista" target="_blank" rel="noopener noreferrer">
                    <button className={styles.learnMoreButton}>
                        <FontAwesomeIcon className={styles.iconClass} icon={faWhatsapp} size="2x" />
                        Fale Conosco no Whatsapp
                    </button>

                </a>
        </div>
      );
    }

export default AreasOfAtuation;
