import React from 'react';
import styles from './Footer.module.css';
import Logo from '../../assets/logorenan.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'


const Footer = () => {
    return (
        <div className={styles.footerContainer}>
            <div className={styles.contentContainer}>
                <img className={styles.logoImage} src={Logo} alt="Renan Roa Advocacia" />
                <p className={styles.textBelowImage}>Escritório 5 estrelas nas Avaliações Google</p>
                <div className={styles.iconContainer}>
                    <div className={styles.iconItem + ' ' + styles.mapIcon}>
                        <a href="https://www.google.com/maps?ll=-23.408469,-51.89989&z=16&t=m&hl=pt-BR&gl=BR&mapclient=embed&cid=3269283732357555661" target="_blank" rel="noopener noreferrer" >
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                            <span>Av. Tuiuti, 2412, sala 12 - Jardim Colina Verde - Maringá - PR, 87043-720</span>

                        </a>

                    </div>

                    <div className={styles.iconItem + ' ' + styles.emailIcon}>
                        <a href="mailto:advocaciarenanroa@gmail.com" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faEnvelope} />
                            <span>advocaciarenanroa@gmail.com</span>
                        </a>
                    </div>


                    <div className={styles.iconItem + ' ' + styles.whatsappIcon}>
                        <a href="https://wa.me/+5544998632325?text=Olá!%20Gostaria%20de%20falar%20com%20um%20dos%20Advogados" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faWhatsapp} />
                            <span>+55 44 99863-2325</span>
                        </a>
                    </div>
                    <div className={styles.iconItem + ' ' + styles.instagramIcon}>
                        <a href="https://www.instagram.com/dr.renanroa/" target="_blank" rel="noopener noreferrer" >
                            <FontAwesomeIcon icon={faInstagram} />
                            <span>@dr.renanroa</span>

                        </a>

                    </div>


                </div>
                <a href="https://wa.me/+5544998632325?text=Olá%20Gostaria%20de%20falar%20com%20o%20Advogado%20Especialista" target="_blank" rel="noopener noreferrer">

                    <button className={styles.learnMoreButton}>
                        <FontAwesomeIcon className={styles.iconClass} icon={faWhatsapp} />
                        Fale Conosco no Whatsapp
                    </button>
                </a>
                
            </div>
            <div className={styles.mapContainer}>
                <iframe className={styles.map}
                    title="officeLocation"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3661.439961272568!2d-51.899890199999994!3d-23.408469099999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ecd70c4309a3db%3A0x2d5ed43bb122a5cd!2sADVOGADO%20CRIMINALISTA%20-%20RENAN%20ROA%20ADVOCACIA!5e0!3m2!1spt-BR!2sbr!4v1693900553473!5m2!1spt-BR!2sbr"
                    width="90%"
                    height="90%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy">
                </iframe>
            </div>
        </div>
    )
}

export default Footer;
